@import 'node_modules/bkn-ui/scss/theme/base';
@import 'node_modules/bkn-ui/scss/theme/type';
@import 'node_modules/bkn-ui/scss/components/layout';
@import 'node_modules/bkn-ui/scss/components/page';
@import 'node_modules/bkn-ui/scss/components/page-loading-indicator';
@import 'node_modules/bkn-ui/scss/components/confirm-modal';
.bkn-select {
  .inactive {
    opacity: 0.4;
  }
}

.main-nav {
  .main-nav-left {
    h1.page-title {
      padding: 0 0.5rem 0 0;
    }
    .toggle-drawer + h1.page-title {
      padding: 0 0.5rem 0 1rem;
    }
  }
}

button:focus,
.btn.btn-icon:focus {
  outline: none;
  box-shadow: none;
}

.bkn-datagrid-header-right .mui-btn-square {
  width: auto;
  height: auto;
}

.bkn-datagrid-header-left .mui-btn-square {
  border-radius: 0 8px 8px 0;
}

iframe.intercom-launcher-frame.intercom-launcher-frame {
  right: 10px;
  bottom: 10px;
}

.intercom-lightweight-app .intercom-launcher {
  right: 10px;
  bottom: 10px;
}
